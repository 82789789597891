<template>
  <div class="top-up container">
    <a-spin :spinning="isLoading" size="large">
      <a-breadcrumb separator="" class="page-breadcrumb">
        <a-breadcrumb-item>
          <router-link to="/mycards">
            {{ $t('route.my_cards') }}
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-separator>
          <a-icon type="right"/>
        </a-breadcrumb-separator>
        <a-breadcrumb-item>{{ $t('route.topup') }}</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="section-title">
        <div>{{ $t('route.topup') }}</div>
        <div class="section-subtitle">{{ $t('common.title_10') }}</div>
      </div>
      <topup-search :iccid="iccid"/>
      <div class="section-products" v-cloak>
        <van-tabs ref="tabs" v-model="active" animated swipeable sticky color="#2564F6" @scroll="scrollCallback"
        :offset-top="79">
          <van-tab :title="area.continentname" v-for="(area, i) in list" :key="i">
            <a-row :gutter="[{ xs: 14, sm: 20, xxl: 30 }, { xs: 14, sm: 20, xxl: 30 }]" class="products">
              <a-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6" v-for="item in area.distictSub" :key="item.district_id">
                <div class="product-item" @click="handleBuyNow(item)" :title="item.district_name">
                  <div class="product-cover">
                    <img class="product-img" :src="item.cover" :alt="item.district_name" :title="item.district_name" />
                  </div>
                  <div class="product-info">
                    <div class="product-name text-overflow-example">{{ item.district_name }}</div>
                    <div class="product-lowest-price">
                      <span class="product-price-from">{{ $t("common.from") }}</span>
                      <span>{{ item.lowest_price }}</span>
                    </div>
                    <div class="product-icon flex">
                      <a-icon type="right" />
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </van-tab>
        </van-tabs>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { debounce } from "../utils/dbucTrtl"
import TopupSearch from '../components/TopupSearch'
export default {
  name: 'Topup',
  components: {
    TopupSearch,
  },
  data() {
    return {
      active: 0,
      isLoading: false,
      isFixed: false,
      list: [],
      iccid:this.$route.query.iccid,
      debouncedResizeHandler:null
    }
  },
  watch: {
    isFixed() {
      this.debouncedResizeHandler()
    },
  },
  mounted() {
    this.init()
    this.debouncedResizeHandler = debounce(this.handleTabsResize, 1000);
  },
  destroyed() {
    this.debouncedResizeHandler = null
  },
  methods: {
    async init() {
      this.isLoading = true
      const { query:{iccid} } = this.$route
      const { data: { continent } } = await this.$http.get(this.APIURL.HOME.INDEX, { iccid }, false)
      this.list = continent.filter(item => item.distictSub.length > 0)
      this.isLoading = false
    },
    scrollCallback({ isFixed }) {
      this.isFixed = isFixed
    },
    async handleTabsResize() {
      console.log("resize");
      this.$refs.tabs.resize();
    },
    handleBuyNow(item) {
      const { district_id:id,district_name:name } = item
      const query = { id, ...(this.iccid&&{iccid:this.iccid}) }
      this.$router.push({ name: 'Details', query });
      window._hmt.push(['_trackEvent', 'button', 'click', `浏览套餐-${name}`]);
    },
  },
}
</script>
<style lang="scss" scoped>
.top-up{
  
}
</style>